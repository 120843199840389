<template>
  <div>
    <el-card shadow="never">
      <div class="mod-sys__role">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
          <el-form-item>
            <el-input v-model="dataForm.name" placeholder="名称" clearable size="small"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="getDataList()" size="small" type="primary">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetSearch" size="small">重置</el-button>
          </el-form-item>
        </el-form>
        <div class="marginBottom">
          <el-button type="primary" @click="addOrUpdateHandle()" size="small">新增</el-button>
        </div>
        <div class="role_content">
          <div class="role_table">
            <el-table v-loading="dataListLoading" :data="dataList" border style="width: 100%;" :height="tabHeight" >
              <el-table-column prop="name" label="名称" header-align="center" align="center"></el-table-column>
              <el-table-column prop="remark" label="备注" header-align="center" align="center"></el-table-column>
              <el-table-column label="操作" fixed="right" header-align="center" align="center" width="150">
                <template slot-scope="scope">
                  <div v-if="scope.row.deptType !== 'dept'">
                    <el-button  type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
                    <el-button  type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
      </div>
    </el-card>
  </div>
</template>

<script>
import AddOrUpdate from './role-add-or-update'

function getClientHeight () {
  let clientHeight = 0
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
  } else {
    clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
  }
  return clientHeight
}

export default {
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/publicRole/getRoleList',
        getDataListIsPage: false,
        deleteURL: '/publicRole',
        deleteIsBatch: false
      },
      dataForm: {
        name: ''
      },
      dataList: [],
      page: 1,                    // 当前页码
      limit: 10,                  // 每页数
      total: 0,                   // 总条数
      dataListLoading: false,     // 数据列表，loading状态
      addOrUpdateVisible: false,
      tabHeight: '400',
    }
  },
  components: {
    AddOrUpdate
  },
  mounted () {
    this.getDataList()
    this.$nextTick(() => {
      // console.log(this.$utils.clientHeight() - 260 + 'px')
      this.tabHeight = getClientHeight() - 260 + 'px'
    })
  },
  methods: {
    getDataList () { // 获取角色列表
      this.$http.get('/publicRole/getRoleList', {
        params: {
          keyWord: this.dataForm.name
        }
      }).then(({ data: res }) => {
        let { code, msg, data } = res
        console.log(code, msg, data)
        if (code === 0 && data) {
          this.dataList = data
          this.total = this.dataList.length
        }
        if (code !== 0) {
          this.$message.error(msg)
        }
      })
    },
    resetSearch () {
      let form = this.$options.data().dataForm
      this.$set(this, 'dataForm', form)
      this.$nextTick(() => {
        this.getDataList()
      })
    },
    // 分页, 每页条数
    pageSizeChangeHandle (val) {
      this.page = 1
      this.limit = val
      this.getDataList()
    },
    // 分页, 当前页
    pageCurrentChangeHandle (val) {
      this.page = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.init()
      })
    },
    // 删除
    deleteHandle (id) {
      if (this.mixinViewModuleOptions.deleteIsBatch && !id && this.dataListSelections.length <= 0) {
        this.$message.warning('请选择需要删除的项目')
        return
      }
      this.$confirm('确定进行[删除]操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete(
          `${this.mixinViewModuleOptions.deleteURL}${this.mixinViewModuleOptions.deleteIsBatch ? '' : '/' + id}`,
          this.mixinViewModuleOptions.deleteIsBatch ? {
            'data': id ? [id] : this.dataListSelections.map(item => item[this.mixinViewModuleOptions.deleteIsBatchKey])
          } : {}
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 500,
            onClose: () => {
              this.getDataList()
            }
          })
        }).catch(() => {})
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-pagination {
    text-align: right;
    margin-top: 6px;
  }
  .marginBottom {
    margin-bottom: 13px;
  }
</style>
